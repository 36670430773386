import {FullAddressContactDetails} from '@wix/ambassador-ecom-v1-checkout/types';

export class ContactModel {
  public fullName: string;
  public company: string;
  public phone: string;
  constructor(contactDetails?: FullAddressContactDetails) {
    this.fullName = `${contactDetails?.firstName ?? /* istanbul ignore next */ ''} ${
      contactDetails?.lastName ?? /* istanbul ignore next */ ''
    }`;
    this.company = contactDetails?.company ?? /* istanbul ignore next */ '';
    this.phone = contactDetails?.phone ?? /* istanbul ignore next */ '';
  }
}
