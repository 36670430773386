import {ILocaleKeys} from '../../locale-keys/LocaleKeys';
import {CheckoutErrorModel} from '../models/CheckoutError.model';
import {MinimumOrderErrorData} from '../services/CheckoutService';

export enum ErrorType {
  MINIMUM_ORDER_AMOUNT = 'MINIMUM_ORDER_AMOUNT',
  GIFT_CARD = 'GIFT_CARD',
  COUPON = 'COUPON',
  GENERAL = 'GENERAL',
  PAYMENT = 'PAYMENT',
}

export const CouponErrorCodes: Record<string, string> = {
  ERROR_COUPON_DOES_NOT_EXIST: 'ERROR_COUPON_DOES_NOT_EXIST',
  ERROR_COUPON_IS_NOT_ACTIVE_YET: 'ERROR_COUPON_IS_NOT_ACTIVE_YET',
  ERROR_COUPON_HAS_EXPIRED: 'ERROR_COUPON_HAS_EXPIRED',
  ERROR_COUPON_USAGE_EXCEEDED: 'ERROR_COUPON_USAGE_EXCEEDED',
  ERROR_COUPON_IS_DISABLED: 'ERROR_COUPON_IS_DISABLED',
  ERROR_INVALID_COMMAND_FIELD: 'ERROR_INVALID_COMMAND_FIELD',
  ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED: 'ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED',
  ERROR_INVALID_SUBTOTAL: 'ERROR_INVALID_SUBTOTAL',
  ERROR_INVALID_PRODUCTS: 'ERROR_INVALID_PRODUCTS',
  ERROR_INVALID_PRODUCT_QUANTITY: 'ERROR_INVALID_PRODUCT_QUANTITY',
  ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS: 'ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS',
};

export const GiftCardErrorCodes: Record<string, string> = {
  GIFT_CARD_CODE_INVALID: 'GIFT_CARD_CODE_INVALID',
  GIFT_CARD_EXPIRED: 'GIFT_CARD_EXPIRED',
  GIFT_CARD_INSUFFICIENT_BALANCE: 'GIFT_CARD_INSUFFICIENT_BALANCE',
  GIFT_CARD_IS_EMPTY: 'GIFT_CARD_IS_EMPTY',
  GIFT_CARD_UNSUPPORTED_STATUS: 'GIFT_CARD_UNSUPPORTED_STATUS',
  GIFT_CARD_GENERAL_ERROR: 'GIFT_CARD_GENERAL_ERROR',
  GIFT_CARD_CURRENCY_INVALID: 'GIFT_CARD_CURRENCY_INVALID',
  GIFT_CARD_DISABLED: 'GIFT_CARD_DISABLED',
  GIFT_CARD_SERVICE_UNAVAILABLE: 'GIFT_CARD_SERVICE_UNAVAILABLE',
};

export const GeneralCheckoutErrorCode: Record<string, string> = {
  EMPTY_CHECKOUT: 'EMPTY_CHECKOUT',
  FULL_OUT_OF_STOCK: 'FULL_OUT_OF_STOCK',
  PARTIAL_OUT_OF_STOCK: 'PARTIAL_OUT_OF_STOCK',
  CHECKOUT_ALREADY_PAID: 'CHECKOUT_ALREADY_PAID',
  NO_SELECTED_CARRIER_SERVICE_OPTION: 'NO_SELECTED_CARRIER_SERVICE_OPTION',
  CALCULATION_ERROR: 'CALCULATION_ERROR',
  GENERAL_ERROR: 'GENERAL_ERROR',
};

export const PaymentErrorCode: Record<string, string> = {
  CHECKOUT_PAYMENT_ALREADY_IN_PROGRESS: 'CHECKOUT_PAYMENT_ALREADY_IN_PROGRESS',
  PAYMENT_ERROR: 'PAYMENT_ERROR',
  EWALLET_ERROR: 'EWALLET_ERROR',
};

export const MinimumOrderAmountErrorCode: Record<string, string> = {
  MINIMUM_ORDER_AMOUNT_NOT_REACHED: 'MINIMUM_ORDER_AMOUNT_NOT_REACHED',
};

export const CheckoutErrorCode: Record<string, string> = {
  ...CouponErrorCodes,
  ...GiftCardErrorCodes,
  ...GeneralCheckoutErrorCode,
  ...MinimumOrderAmountErrorCode,
  ...PaymentErrorCode,
};

interface Action {
  name: string;
  fn: () => void;
}

export interface ErrorProps {
  title: string;
  description: string;
  action: Action;
}

export const getErrorPropsFromCode = (
  error: CheckoutErrorModel | undefined,
  localeKeys: ILocaleKeys,
  {
    clickOnBackToSite,
    handleClickOnReturnToCart,
    handleDialogOnClose,
  }: {clickOnBackToSite: () => void; handleClickOnReturnToCart: () => void; handleDialogOnClose: () => void},
  hasPickupOption: boolean,
  hasCart?: boolean
): ErrorProps => {
  switch (error?.code) {
    case CheckoutErrorCode.EMPTY_CHECKOUT:
      return {
        title: localeKeys.cart.validation.error.EMPTY_CART.title(),
        description: localeKeys.cart.validation.error.EMPTY_CART.content(),
        action: {name: localeKeys.cart.validation.error.EMPTY_CART.button(), fn: clickOnBackToSite},
      };
    case CheckoutErrorCode.FULL_OUT_OF_STOCK:
      return {
        title: localeKeys.cart.validation.error.FULL_OUT_OF_STOCK.title(),
        description: localeKeys.cart.validation.error.FULL_OUT_OF_STOCK.content(),
        action: {
          name: localeKeys.cart.validation.error.FULL_OUT_OF_STOCK.button(),
          fn: clickOnBackToSite,
        },
      };
    case CheckoutErrorCode.PARTIAL_OUT_OF_STOCK:
      return {
        title: localeKeys.cart.validation.error.PARTIAL_OUT_OF_STOCK.title(),
        description: localeKeys.cart.validation.error.PARTIAL_OUT_OF_STOCK.content(),
        action: {
          name: localeKeys.cart.validation.error.PARTIAL_OUT_OF_STOCK.button(),
          fn: handleDialogOnClose,
        },
      };
    case CheckoutErrorCode.GIFT_CARD_CODE_INVALID:
      return {
        title: localeKeys.checkout.orderFailedModal.invalidCardError.title(),
        description: localeKeys.checkout.orderFailedModal.invalidCardError.content(),
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_EXPIRED:
      return {
        title: localeKeys.checkout.orderFailedModal.expiredCardError.title(),
        description: localeKeys.checkout.orderFailedModal.expiredCardError.content(),
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_INSUFFICIENT_BALANCE:
      return {
        title: localeKeys.checkout.orderFailedModal.balanceChangedError.title(),
        description: localeKeys.checkout.orderFailedModal.balanceChangedError.content(),
        action: getReturnToCheckoutAction(),
      };

    case CheckoutErrorCode.GIFT_CARD_IS_EMPTY:
      return {
        title: localeKeys.checkout.orderFailedModal.insufficientFundsError.title(),
        description: localeKeys.checkout.orderFailedModal.insufficientFundsError.content(),
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_UNSUPPORTED_STATUS:
    case CheckoutErrorCode.GIFT_CARD_GENERAL_ERROR:
      return {
        title: localeKeys.checkout.orderFailedModal.genericFallbackError.title(),
        description: localeKeys.checkout.orderFailedModal.genericFallbackError.content(),
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_CURRENCY_INVALID:
      return {
        title: localeKeys.checkout.paymentDetails.enterGiftCard.error.replaceCurrency.title(),
        description: localeKeys.checkout.paymentDetails.enterGiftCard.error.replaceCurrency.content(),
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_DISABLED:
      return {
        title: localeKeys.checkout.paymentDetails.enterGiftCard.error.genericFallback(),
        description: '',
        action: getReturnToCheckoutAction(),
      };
    case CheckoutErrorCode.GIFT_CARD_SERVICE_UNAVAILABLE:
      return {
        title: localeKeys.checkout.paymentDetails.enterGiftCard.error.serviceDown.title(),
        description: localeKeys.checkout.paymentDetails.enterGiftCard.error.serviceDown.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.paymentDetails.enterGiftCard.error.serviceDown.cta()),
      };
    case CheckoutErrorCode.ERROR_INVALID_COMMAND_FIELD:
    case CheckoutErrorCode.ERROR_COUPON_DOES_NOT_EXIST:
      return {
        title: localeKeys.checkout.promoCodeError.doesntExist.title(),
        description: localeKeys.checkout.promoCodeError.doesntExist.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.doesntExist.cta()),
      };
    case CheckoutErrorCode.ERROR_COUPON_IS_NOT_ACTIVE_YET:
      return {
        title: localeKeys.checkout.promoCodeError.notActiveYet.title(),
        description: localeKeys.checkout.promoCodeError.notActiveYet.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.notActiveYet.cta()),
      };
    case CheckoutErrorCode.ERROR_COUPON_IS_DISABLED:
      return {
        title: localeKeys.checkout.promoCodeError.disabled.title(),
        description: localeKeys.checkout.promoCodeError.disabled.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.disabled.cta()),
      };
    case CheckoutErrorCode.ERROR_COUPON_USAGE_EXCEEDED:
    case CheckoutErrorCode.ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED:
      return {
        title: localeKeys.checkout.promoCodeError.exceededUsage.title(),
        description: localeKeys.checkout.promoCodeError.exceededUsage.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.exceededUsage.cta()),
      };
    case CheckoutErrorCode.ERROR_COUPON_HAS_EXPIRED:
      return {
        title: localeKeys.checkout.promoCodeError.expired.title(),
        description: localeKeys.checkout.promoCodeError.expired.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.expired.cta()),
      };
    case CheckoutErrorCode.ERROR_INVALID_SUBTOTAL:
      return {
        title: localeKeys.checkout.promoCodeError.invalidSubtotal.title({
          subtotal: error?.data?.description,
        }),
        description: localeKeys.checkout.promoCodeError.invalidSubtotal.subtitle(),
        action: getContinueShoppingAction(localeKeys.checkout.promoCodeError.invalidSubtotal.cta()),
      };
    case CheckoutErrorCode.ERROR_INVALID_PRODUCTS:
      return {
        title: localeKeys.checkout.promoCodeError.invalidProducts.title(),
        description: localeKeys.checkout.promoCodeError.invalidProducts.subtitle(),
        action: getContinueShoppingAction(localeKeys.checkout.promoCodeError.invalidProducts.cta()),
      };
    case CheckoutErrorCode.ERROR_INVALID_PRODUCT_QUANTITY:
      return {
        title: localeKeys.checkout.promoCodeError.invalidProductQuantity.title(),
        description: localeKeys.checkout.promoCodeError.invalidProductQuantity.subtitle({
          quantity: error?.data?.description,
        }),
        action: getContinueShoppingAction(localeKeys.checkout.promoCodeError.invalidProductQuantity.cta()),
      };
    case CheckoutErrorCode.ERROR_NOT_APPLICABLE_FOR_SUBSCRIPTIONS:
      return {
        title: localeKeys.checkout.promoCodeError.subscriptionsNotValid.title(),
        description: localeKeys.checkout.promoCodeError.subscriptionsNotValid.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.promoCodeError.subscriptionsNotValid.cta()),
      };
    case CheckoutErrorCode.NO_SELECTED_CARRIER_SERVICE_OPTION:
      return {
        title: localeKeys.checkout.informative_modal.unsupported_shipping_destination_title(),
        description: localeKeys.checkout.informative_modal.unsupported_shipping_destination(),
        action: getReturnToCartAction(),
      };
    case CheckoutErrorCode.MINIMUM_ORDER_AMOUNT_NOT_REACHED:
      if (hasPickupOption) {
        return {
          title: localeKeys.checkout.minimumOrderModal.withPickup.title({
            minAmount: (error?.data as MinimumOrderErrorData).minimumOrderAmount.formattedConvertedAmount,
          }),
          description: localeKeys.checkout.minimumOrderModal.withPickup.body({
            additionalAmount: (error?.data as MinimumOrderErrorData).remaining.formattedConvertedAmount,
          }),
          action: {
            name: localeKeys.checkout.minimumOrderModal.withPickup.gotItCTA(),
            fn: handleDialogOnClose,
          },
        };
      } else {
        return {
          title: localeKeys.checkout.minimumOrderModal.withoutPickup.title({
            minAmount: (error?.data as MinimumOrderErrorData).minimumOrderAmount.formattedConvertedAmount,
          }),
          description: localeKeys.checkout.minimumOrderModal.withoutPickup.body({
            additionalAmount: (error?.data as MinimumOrderErrorData).remaining.formattedConvertedAmount,
          }),
          action: getContinueShoppingAction(),
        };
      }
    case CheckoutErrorCode.CHECKOUT_PAYMENT_ALREADY_IN_PROGRESS:
      return {
        title: localeKeys.cart.validation.error.PAYMENT_IN_PROCESS.title(),
        description: localeKeys.cart.validation.error.PAYMENT_IN_PROCESS.content(),
        action: getReturnToCheckoutAction(localeKeys.cart.validation.error.PAYMENT_IN_PROCESS.button()),
      };
    case CheckoutErrorCode.PAYMENT_ERROR:
    case CheckoutErrorCode.EWALLET_ERROR:
      return {
        title: localeKeys.checkout.page.generalPaymentError.title(),
        description: localeKeys.checkout.page.generalPaymentError.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.page.generalPaymentError.cta()),
      };
    case CheckoutErrorCode.CALCULATION_ERROR:
      return {
        title: localeKeys.checkout.page.technicalCalculationError.title(),
        description: localeKeys.checkout.page.technicalCalculationError.subtitle(),
        action: getReturnToCheckoutAction(localeKeys.checkout.page.technicalCalculationError.cta()),
      };
    case CheckoutErrorCode.GENERAL_ERROR:
    default:
      return {
        title: localeKeys.checkout.informative_modal.cashier_internal_error_title(),
        description: localeKeys.checkout.informative_modal.cashier_internal_error(),
        action: getReturnToCartAction(),
      };
  }

  function getReturnToCheckoutAction(buttonText?: string) {
    return {
      name: buttonText ?? localeKeys.checkout.orderFailedModal.returnToCheckout.button(),
      fn: handleDialogOnClose,
    };
  }

  function getContinueShoppingAction(buttonText?: string) {
    return {
      name: buttonText ?? localeKeys.checkout.minimumOrderModal.withoutPickup.continueShoppingCTA(),
      fn: clickOnBackToSite,
    };
  }

  function getReturnToCartAction() {
    if (!hasCart) {
      return getContinueShoppingAction();
    }
    return {
      name: localeKeys.checkout.informative_modal.return_to_cart(),
      fn: handleClickOnReturnToCart,
    };
  }
};
