/* eslint-disable-next no-shadow */
export enum FedopsInteractions {
  test = 'test-interaction',
  ApplyCouponInteraction = 'apply-coupon-interaction',
  ApplyGiftCardInteraction = 'apply-gift-card-interaction',
  PlaceOrderInteraction = 'place-order-interaction',
  ChangeDeliveryMethodInteraction = 'change-delivery-method-interaction',
}

export const SPECS = {
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  AddUnderTheSummarySlotToCheckout: 'specs.stores.AddUnderTheSummarySlotToCheckout',
  AllowCheckoutOOIForAnyFlow: 'specs.stores.AllowCheckoutOOIForAnyFlow',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  RedirectToOldCheckoutOnNonEnglish: 'specs.stores.RedirectToOldCheckoutOnNonEnglish',
  ShouldRemovePaymentProvidersForBI: 'specs.stores.ShouldRemovePaymentProvidersForBI',
  NewCheckoutAddMultilingualHeader: 'specs.stores.NewCheckoutAddMultilingualHeader',
};

export enum FieldMasks {
  customField = 'customFields',
  selectedCarrierServiceOption = 'shippingInfo.selectedCarrierServiceOption',
  billingAddress = 'billingInfo.address',
  shippingAddress = 'shippingInfo.shippingDestination.address',
}

export const MAX_ITEM_OPTION_LENGTH = 500;

export const THUMBNAIL_WIDTH = 60;

export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const DELIVERY_METHOD_GROUP_NAME = 'deliveryOptions';

export const BASE_URL = 'https://www.checkout.com/';

export const CART_PAGE_URL = 'https://www.checkout.com/cart-page';

export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 250;
export const MAX_ZIP_CODE_FIELD_LENGTH = 250;

export const PAYPAL = 'PayPal';
